import React, { useState, useRef, useEffect } from "react"
import styles from "./event.module.css"
import ReactMarkdown from "react-markdown"
import RemarkBreaks from "remark-breaks"
import { Flex, Box, Collapse, Text } from "@chakra-ui/core"
import { css } from "@emotion/core"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"

import ImageWithOverlay from "components/imageWithOverlaycolorOrChild"
// import Lightbox from "containers/lightbox"

import { getMonat, getTag } from "utilities/datum"

const scrollToRefFunc = ref => {
  typeof window !== "undefined" &&
    window.scrollTo({
      top: ref.current.offsetTop - 150,
      left: 0,
      behavior: "smooth",
    })
}

const Event = ({
  date,
  title,
  subtitle,
  text,
  image,
  imageGray,
  lightbox,
  gallery,
  moreInfo = false,
  setData,
  viewLightbox,
  openDefault = false,
  alt,
  copyright,
  showLightbox,
}) => {
  const [show, setShow] = useState(moreInfo)
  const { t } = useTranslation()
  const { language } = useI18next()
  const EN = language === "en"

  const scrollToRef = useRef(null)
  useEffect(() => {
    show && scrollToRefFunc(scrollToRef)
    show && lightbox && funcData()
  }, [show, moreInfo, lightbox])
  // const executeScroll = () => scrollToRef(scrollToRef)

  const imageAspectRatio =
    (image &&
      image.childImageSharp &&
      image.childImageSharp.fluid.aspectRatio) ||
    1

  const funcData = () => {
    setData({
      title,
      text,
      gallery,
      subtitle,
    })
    viewLightbox(true)
  }

  useEffect(() => {
    openDefault && setShow(true)
  }, [openDefault])

  // Date
  let formattedDate = new Date(date)
  const monat = t(getMonat(formattedDate.getMonth()))
  const tag = t(getTag(formattedDate.getDay()))
  const jahr = formattedDate.getFullYear()

  const datePart1 = `${formattedDate.getDate().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}. ${monat} ${jahr}`
  const datePart2 = `${tag} ${formattedDate.getHours().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}.${formattedDate.getMinutes().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })} ${!EN ? "Uhr" : ""}`
  /// Date

  return (
    <>
      <Flex
        direction="column"
        pr={["8em", "5em", "5em"]}
        onClick={() => (lightbox ? funcData() : setShow(!show))}
        cursor={show && !lightbox ? "url(/close.svg), auto" : "pointer"}
        flex="0 1"
        ref={scrollToRef}
        position="relative"
        css={css`
          .more-info {
            visibility: hidden;
          }
          .image-gray {
            visibility: ${show ? "hidden" : "visible"};
          }
          @media (hover) {
            &:hover .more-info {
              visibility: ${show ? "hidden" : "visible"};
            }
          }
        `}
      >
        {date && (
          <Box
            color="red"
            fontSize={["5-5xl", "4xl", "3xl"]}
            pb={["0.2em", "0.1em", "0.2em"]}
            fontFamily="Overpass"
            lineHeight="1.25em"
          >
            <Text p="0" fontFamily="inherit" fontWeight="bold">
              {datePart1}
            </Text>
            {datePart2}
          </Box>
        )}
        <ImageWithOverlay
          image={image && image.childImageSharp && image.childImageSharp.fluid}
          imageGray={
            imageGray &&
            imageGray.childImageSharp &&
            imageGray.childImageSharp.fluid
          }
          aspectRatio={imageAspectRatio}
          onlyOverlayAndChild={true}
          onlyShowImage={show}
          frame={lightbox}
          alt={alt}
        >
          <Box
            className="more-info"
            pos="absolute"
            top="0"
            color="white"
            fontSize={["5-5xl", "4xl", "3xl"]}
            fontFamily="Traulha Italica, sans-serif"
            bg="red"
            h="100%"
            w="100%"
            zIndex="5"
            p={["0.5em", "0.5em", "0.5em"]}
          >
            {t("Weitere Informationen")}
          </Box>
        </ImageWithOverlay>
        {title && (
          <Box
            color="red"
            pt={["0.6em", "0.6em", "0.7em"]}
            fontSize={["5-5xl", "4xl", "3xl"]}
            lineHeight="1.25em"
          >
            {title}
          </Box>
        )}
        {subtitle && (
          <Box
            color="green"
            fontSize={["5-5xl", "4xl", "3xl"]}
            lineHeight="1.25em"
          >
            {subtitle}
          </Box>
        )}
        {text && !lightbox && (
          <Collapse
            py={["1em", "1em", "0.5em"]}
            color="black"
            isOpen={show}
            fontSize={["4-5xl", "3xl", "xl"]}
            fontFamily="Overpass"
          >
            <ReactMarkdown
              source={text}
              className={styles.event}
              plugins={[RemarkBreaks]}
            />
            <Box
              letterSpacing="normal"
              color="green"
              lineHeight={["1.15em", "1.15em", "1.15em"]}
              fontSize={["4-5xl", "3xl", "xl"]}
              fontFamily={"Overpass"}
            >
              {copyright}
            </Box>
          </Collapse>
        )}
      </Flex>
    </>
  )
}

export default Event
