import React from "react"
import { graphql } from "gatsby"

import { VeranstaltungenTempl } from "../../pageTemplates/veranstaltungen"

const Veranstaltungen = ({ data, location }) => (
  <VeranstaltungenTempl data={data} location={location} en />
)

export const query = graphql`
  query {
    strapi {
      veranstaltungen {
        meta_en {
          description
          title
        }
      }
      events(sort: "date:asc") {
        id
        cover {
          __typename
          url
          caption
          alternativeText
          imageFileGray: imageFile {
            childImageSharp {
              fluid(duotone: { highlight: "#ffffff", shadow: "#007c60" }) {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
              }
            }
          }
          imageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
              }
            }
          }
        }
        gallery {
          url
          caption
          alternativeText
          imageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
              }
            }
          }
        }
        text
        subtitle
        title
        lightbox
        unpublish_on_date
        publish_on_date
        date
        openDefault
      }
    }
  }
`

export default Veranstaltungen

// date(formatString: "d.MM.YY")
