import React, { useState } from "react"

import { Box, Text } from "@chakra-ui/core"

import Layout from "containers/layout"
import SEO from "components/seo"
import Masonry from "containers/masonry"
import Event from "containers/event"
import Lightbox from "containers/lightbox"
import theme from "root/theme"
import { onlyClient } from "./index"
import { useTranslation } from "gatsby-plugin-react-i18next"

// import { css } from "@emotion/core"

export const VeranstaltungenTempl = ({ location, data, en: EN }) => {
  const [lightboxData, setLightboxData] = useState({})
  const [viewLightbox, setViewLightbox] = useState(false)
  const { t } = useTranslation()

  const locationStateAvailable = location.state
  const activeEvent = locationStateAvailable && location.state.linkToID

  const futureEvents = data?.strapi?.events
    .filter(item => {
      return (
        (Date.parse(item.publish_on_date) <= Date.now() ||
          Date.parse(item.publish_on_date) === null) &&
        (item.unpublish_on_date
          ? Date.parse(item.unpublish_on_date) >= Date.now()
          : true) &&
        Date.parse(item.date) >= Date.now() &&
        item
      )
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date))

  const pastEvents = data?.strapi?.events
    .filter(item => {
      return (
        (Date.parse(item.publish_on_date) <= Date.now() ||
          Date.parse(item.publish_on_date) === null) &&
        (item.unpublish_on_date
          ? Date.parse(item.unpublish_on_date) >= Date.now()
          : true) &&
        Date.parse(item.date) <= Date.now() &&
        item
      )
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date))

  return (
    <Layout path={location.pathname} bgHeader="yellow">
      <SEO
        title={
          data.strapi?.veranstaltungen[EN ? "meta_en" : "meta"]?.title ??
          t("Veranstaltungen")
        }
        description={
          data.strapi?.veranstaltungen[EN ? "meta_en" : "meta"]?.description
        }
      />
      <Box
        pt={["16em", "15.5em", "13em"]}
        pr={["4em", "10em", "5em"]}
        bg="inherit"
        minHeight={["100vh", "100vh", "calc(100vh - 5.6em)"]}
        w="100%"
      >
        {data.strapi.events && data.strapi.events.length > 0 && onlyClient && (
          <>
            {futureEvents.length > 0 && (
              <>
                <Masonry
                  breakpointColumns={{
                    2: "(min-width: 48em)",
                    3: "(min-width: 75em)",
                  }}
                  defaultColumn={1}
                  gap={["10em", "9em", "5em"]}
                >
                  {futureEvents.map((item, index) => {
                    return (
                      <Event
                        key={index}
                        date={item.date}
                        title={item.title}
                        subtitle={item.subtitle}
                        imageGray={item.cover && item.cover.imageFileGray}
                        image={item.cover && item.cover.imageFile}
                        text={item.text}
                        openDefault={item.openDefault && !item.lightbox}
                        lightbox={item.lightbox}
                        gallery={item.gallery}
                        moreInfo={activeEvent === item.id}
                        setData={setLightboxData}
                        viewLightbox={setViewLightbox}
                        copyright={item.cover && item.cover.caption}
                        alt={item.cover && item.cover.alternativeText}
                      />
                    )
                  })}
                </Masonry>
                <hr style={{ borderTop: `1px solid ${theme.colors.green}` }} />
              </>
            )}
            <Text
              color="red"
              fontSize={["6xl", "5xl", "4xl"]}
              pb={["1.25em", "1.25em", "1.25em"]}
              pt={["2.6em", "3em", "2em"]}
              lineHeight="1.25em"
            >
              {t("Vergangene Veranstaltungen")}
            </Text>
            {onlyClient && (
              <Masonry
                breakpointColumns={{
                  2: "(min-width: 48em)",
                  3: "(min-width: 75em)",
                }}
                defaultColumn={1}
                gap={["10em", "9em", "5em"]}
                paddingTop={[
                  ["10em", "10em", "10em"],
                  ["4em", "4em", "4em"],
                  ["10em", "10em", "15em"],
                ]}
              >
                {pastEvents.map((item, index) => {
                  return (
                    <Event
                      key={index}
                      date={item.date}
                      title={item.title}
                      subtitle={item.subtitle}
                      imageGray={item.cover && item.cover.imageFileGray}
                      image={item.cover && item.cover.imageFile}
                      text={item.text}
                      lightbox={item.lightbox}
                      gallery={item.gallery}
                      moreInfo={activeEvent === item.title}
                      setData={setLightboxData}
                      viewLightbox={setViewLightbox}
                      copyright={item.cover && item.cover.caption}
                      alt={item.cover && item.cover.alternativeText}
                    />
                  )
                })}
              </Masonry>
            )}
            <Lightbox
              text={lightboxData.text}
              title={lightboxData.title}
              subtitle={lightboxData.subtitle}
              images={lightboxData.gallery}
              visibleFunc={setViewLightbox}
              visible={viewLightbox}
              animate={true}
              viewImageInfo={viewLightbox}
            />
          </>
        )}
      </Box>
    </Layout>
  )
}

export default VeranstaltungenTempl
